import React from "react";
import Main from "../components/main";
import UserCheck from "../components/auth";


export default function Index(props) {
  return (<Main>
    <UserCheck {...props} />
  </Main>);
}

