import React, {useEffect, useState} from "react";

import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "gatsby";
import {location} from "window-or-global";

import Main from "../main";
import Header from "../section/header";
import Footer from "../section/footer";
import LoginControl from "../../controls/login";
import ForgotPassword from "../forgot-password/index";

import LoginBgImage from "../../../static/content/login/zolt-login-bg.png";
import ShuttleVerticalImage from "../../../static/content/vertical-rocket.png";
import LogoImage from "../../../static/content/zolt-logo.png";
import MfaPanel from "./mfa";




const LoginPage = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [mfaMethod, setMfaMethod] = useState(false);
  const [allowedOptions, setAllowedOptions] = useState(["admin-generated-otp"]);
  const [userData, setUserData] = useState(null);
  const [mfa, setMfa] = useState(null);

  useEffect(() => {
    if (userData) {
      setShowMfa(false);
    }
  }, []);


  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  };

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  };


  return (
    <Main>
      <Header />
      <div className="vw-login-page">
        {location?.search?.indexOf("EOVERLIMIT") > -1 ? (
          <Row
            className="vw-alert red no-gutters"
            onClick={() => (window.location.href = `${process.env.PORTAL_URL}/`)}
          >
            <Col>
              {
                "You have reached the data limit associated with your plan. Click here to assign a new plan."
              }
            </Col>
          </Row>
        ) : (
          undefined
        )}
        <div
          className="vw-landing section-1"
          style={{
            backgroundColor: "#338FCB",
            backgroundImage: `url(${LoginBgImage})`,
            backgroundSize: "cover",
          }}
        >
          <div>
            <Container>
              {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
              <Row className="align-items-center vw-fs">
                <Col xs={12} lg={6}>
                  <img src={ShuttleVerticalImage}
                    style={{position: "absolute", marginLeft: -260, height: 500}}
                  />
                  <Col md={12}>
                    <div className="vw-eligible-panel" style={{marginTop: 30, marginBottom: 30}}>
                      <Container fluid>
                        <Row>
                          <Col xs={12} className="d-none d-lg-block d vw-social">
                            <img src={LogoImage} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="vw-title">
                            {"The fast fixed broadband provider"}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="vw-subtitle">
                            {"Get high-performance internet now"}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="vw-btn">
                            <Link className="vw-button orange" style={{padding: "12px 40px"}} to="/join-us">
                              {"Join us!"}
                            </Link>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Col>
                <Col xs={12} lg={5}>
                  <Modal
                    show={showMfa && userData}
                    className="login-mfa-panel-modal"
                  >
                    <MfaPanel
                      mfaMethod={mfaMethod}
                      setShowMfa={setShowMfa}
                      mfa={mfa}
                      allowedOptions={allowedOptions}
                      setMfaMethod={setMfaMethod}
                      userData={userData}
                      setUserData={setUserData}/>
                  </Modal>
                  {!showMfa && <Container>
                    <div className="vw-login-panel mb-5">
                      <Row>
                        <Col xs={12} className="vw-title">
                          {"LOGIN"}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="vw-subtitle">
                          {"Zolt Internet Portal"}
                        </Col>
                      </Row>
                      <LoginControl
                        authUrl={process.env.AUTH_URL}
                        portalUrl={process.env.PORTAL_URL}
                        apiUrl={process.env.BACKEND_URL}
                        setShowMfa={setShowMfa}
                        setMfa={setMfa}
                        setAllowedOptions={setAllowedOptions}
                        setUserData={setUserData}
                      >
                        <Col xs={12} sm={6} className="d-flex align-items-center">
                          <a className="forgot-password" onClick={handleClickForgotPassword}>{"Forgot Password?"}</a>
                        </Col>
                      </LoginControl>
                    </div>
                  </Container>}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer colour="black" />
      </div>
    </Main>
  );
};

export default LoginPage;
