import React, { useRef, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import Formsy from "formsy-react";
import {Link} from "gatsby";
import TextField from "../bootstrap/input";
import Captcha from "../bootstrap/captcha";
import Verification from "./verification";

import Modal from "../bootstrap/modal";

export default function Index(props) {
  const { onClose } = props;
  const [loading, setLoading] = useAsyncSetState(false);
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [captchaRefreshKey, setRefreshKey] = useAsyncSetState(new Date());
  const [showVerification, setVerification] = useAsyncSetState(false);

  const formRef = useRef(null);

  const handleSubmit = async ({ email, captcha, captchaKey }) => {
    try {
      await setLoading(true);
      const response = await fetch(
        `${process.env.BACKEND_URL}rest.api/forgot-request`,
        {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            captcha,
            captchaKey,
          }),
        }
      );
      switch (response.status) {
        case 503:
          await setErrorMessage(() => <>{"An error has occurred"}</>);
          await setRefreshKey(new Date());
          break;
        case 401:
          await setRefreshKey(new Date());
          const { error } = await response.json();
          switch (error) {
            case "EEXISTS":
              await setErrorMessage(() => (
                <>
                  {"This email address already registered, "}
                  <Link to="/forgot-password">{"Click Here"}</Link>
                  {" to reset your password"}
                </>
              ));
              break;
            case "ECAPTCHA":
              await setErrorMessage(() => (
                <>{"Invalid captcha code provided"}</>
              ));
              break;
            default:
              await setErrorMessage(error);
              break;
          }
          break;
        case 200:
          await setErrorMessage(null);
          await setVerification(true);
        // return window.location = `/welcome?firstName=${data.firstName}&userName=${userName}`;
      }
    } catch (err) {
      console.log("err", err);
      await setErrorMessage("An error has occurred");
    }
    return setLoading(false);
  };

  return (
    <Modal
      title="Forgot Password"
      show
      onClose={onClose}
      centered
      footer={
        !showVerification ? (<Container>
          <Row>
            <Col xs="auto ml-auto">
              <Button
                variant="light"
                type="button"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="login"
                className="vw-button"
                type="submit"
                disabled={loading}
                onClick={() => formRef.current.submit()}
              >
                {"Submit"}
              </Button>
            </Col>
          </Row>
        </Container>) : undefined
      }
      // className="fp-modal"
    >
      {showVerification ? (
        <Verification setStateVerification={setVerification} />
      ) : (
        <Fragment>
          {errorMessage && (
            <Row>
              <Col>
                <div className="alert alert-danger mb-3">{errorMessage}</div>
              </Col>
            </Row>
          )}
          <Formsy ref={formRef} onValidSubmit={handleSubmit}>
            <Row>
              <Col>
                <div className="alert alert-primary my-2">
                  {"Enter the email you use to register and we'll help you create a new password"}
                </div>
              </Col>
            </Row>
            <Row className="field-row mb-2">
              <Col xs={12} className="vw-login-field">
                <TextField
                  className="vw-form-field"
                  name="email"
                  autoComplete="off"
                  placeholder="Email Address"
                  disabled={loading}
                  required
                />
              </Col>
            </Row>
            <Row className="field-row captcha">
              <Col>
                <Captcha name="captcha" refreshKey={captchaRefreshKey} />
              </Col>
            </Row>
          </Formsy>
        </Fragment>
      )}
    </Modal>
  );
}
