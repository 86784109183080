import React, {useContext} from "react";
import OtpInput from "../../../otp-input";
import {mfaContext} from "..";

const MFATotpPanel = () => {
  const {loading, setOtp, setOtpError, otp, inputFocus, setInputFocus, otpError, setMfaMethod, setShowMfa} = useContext(mfaContext);

  return (
    <div className="mfa-panel">
      <p className="mfa-heading pb-3">{"Enter your code"}</p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        "We have identified that you have registered a 2FA device."}
      </p>
      <p className="mfa-sub-heading text-justify">{loading ? <i className="fad fa-spinner"/> :
        "Enter the code from the registered device to continue."}
      </p>
      <OtpInput
        count={6}
        value={otp}
        onChange={value => {
          setOtp(value);
          setOtpError(null);
        }}
        inputFocus={inputFocus}
        setInputFocus={setInputFocus}
      />
      {otpError && <div className="mfa-otp-input-error">{otpError}</div>}
      {/* <div className="mfa-actions-container">
        <Button
          variant="login"
          onClick={() => handleOtpSubmit(otp.join(""))}
          disabled={otpLoading}
        >
          {"Submit"}
        </Button>
      </div> */}
      <div className="mfa-other-options-container">
        <span onClick={() => setMfaMethod(null)}>
          {"Use another method to receive the code"}
        </span>
        <span
          onClick={() => {
            setShowMfa(false);
            setMfaMethod(null);
          }}
        >{"Sign in to a different account"}</span>
      </div>
    </div>
  );
};

export default MFATotpPanel;
